<template>
  <SignUpLayout>
    <MobileHeader :title="'업종선택'" :router="backBtn" />
    <div class="signup_biz">
      <div class="radioWrap">
        <div class="radioItem" v-for="(bto, idx) in bizTypeOpts" :key="idx">
          <input type="radio" v-model="bizType" :value="bto.value" />
          <label>{{ bto.name }}</label>
          <img :src="require(`@/assets/images/account/ico_${bto.img}.png`)" />
        </div>
      </div>
      <b-btn @click="submit">선택완료</b-btn>
    </div>
  </SignUpLayout>
</template>

<script>
import "./signup_biz.scss";
import SignUpLayout from "@/views/pages/account/signup/layout/SignUpLayout.vue";
import MobileHeader from "@/views/pages/account/signup/module/MobileHeader.vue";
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";

export default {
  name: "signup_biz",
  components: { MobileHeader, SignUpLayout },
  data: () => {
    return {
      bizType: "",
      bizTypeOpts: [
        { value: "mart", name: "할인마트", img: "cart" },
        { value: "butcher", name: "정육", img: "meat" },
        { value: "veg", name: "채소/과일", img: "apple" },
        { value: "side", name: "반찬", img: "egg" }
      ]
    };
  },
  computed: { ...signUpComputed },
  methods: {
    ...signUpMethods,
    backBtn() {
      this.$router.push("/login");
    },
    submit() {
      // console.log(this.signUpObj);
      if (this.bizType === "") return alert("선택하신 항목이 없습니다.");
      this.setBusinessTypeAct({ payload: this.bizType });
      this.setBusinessTypeValidAct({ payload: true });
      this.$router.push("/signup");
    }
  }
};
</script>
